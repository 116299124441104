<template>
  <div class="archive-rent-form-content">
    <fd-form ref="archiveRentForm">
      <ta-form
        ref="taForm"
        isEdit
        :stickyOffset="stickyOffset"
        :taGeneral.sync="archiveContent.tenancyAgreement"
        :taTenant.sync="archiveContent.tenantInfo"
        :taLandlord.sync="archiveContent.landlordInfo"
        :isLoading.sync="isLoading"
        required
        @submit="$emit('submit', archiveContent)"
      ></ta-form>
    </fd-form>
  </div>
</template>

<script>
import { tenantDetail, landlordDetail } from "@/models/rentTA";
import { cloneDeep } from "lodash";

export default {
  components: {
    TaForm: () => import("@/modules/Rent/components/TAForm")
  },
  mixins: [],
  props: {
    propertyId: {
      type: [String, Number]
    },
    content: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    stickyOffset: {
      type: Number
    }
  },
  data: function () {
    return {
      archiveContent: {
        propertyId: this.propertyId,
        tenancyAgreement: {},
        tenantInfo: cloneDeep(tenantDetail),
        landlordInfo: cloneDeep(landlordDetail)
      }
    };
  },
  computed: {},
  watch: {
    archiveContent: {
      deep: true,
      handler(val) {
        this.$emit("update:content", val);
      }
    },
    content: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.isEdit) {
          this.archiveContent = this._.cloneDeep(val);
        }
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit) {
      this.archiveContent = this._.cloneDeep(this.content);
    }

    // this.fillFakeData();
  },
  methods: {
    fillFakeData() {
      const fakePicture = {
        id: "",
        uploadType: "s3",
        originalName: "E6G5ZUDWUAI7Ghq.png",
        fileName: "259cfe0b4ca2a7f962a2f87a76226.png",
        mimeType: "image/png",
        path: "dev/images/259cfe0b4ca2a7f962a2f87a76226.png",
        url:
          "https://steadee-file-upload.s3.ap-southeast-1.amazonaws.com/dev/images/259cfe0b4ca2a7f962a2f87a76226.png"
      };
      this.archiveContent.tenantInfo = {
        id: "",
        authorizationFiles: [],
        companyName: "",
        companyNo: "",
        consumerType: "Individual",
        otherDocuments: [],
        ssmFiles: [],
        relationshipFiles: [],
        users: [
          {
            id: "",
            name: "Desmond Lee",
            icPassportType: "NRIC",
            icPassport: "990423015067",
            icBackPhoto: [fakePicture],
            icFrontPhoto: [fakePicture],
            passportPhoto: [],
            nationality: "Malaysia",
            contact: "0132464849",
            relationshipId: "",
            address: "",
            isFirstPerson: false,
            isDelete: false
          }
        ]
      };

      this.archiveContent.landlordInfo = {
        bank: "RHB Bank",
        accountName: "Lay Rou Moe",
        accountNumber: "54098808501981081",
        id: "",
        authorizationFiles: [],
        companyName: "",
        companyNo: "",
        consumerType: "Individual",
        otherDocuments: [],
        ssmFiles: [],
        relationshipFiles: [],
        users: [
          {
            id: "",
            name: "Lay Rou Moe",
            icPassportType: "NRIC",
            icPassport: "78910261101065",
            icBackPhoto: [fakePicture],
            icFrontPhoto: [fakePicture],
            passportPhoto: [],
            nationality: "Malaysia",
            contact: "012356544467",
            relationshipId: "",
            address: "",
            isFirstPerson: false,
            isDelete: false
          }
        ]
      };

      this.archiveContent.tenancyAgreement = {
        agreementDate: "",
        terms: "1",
        commencementDate: "2022-06-01",
        dateOfExpiry: "2023-06-01",
        rentFreePeriodFrom: "",
        rentFreePeriodTo: "",
        monthlyRent: "2500",
        securityDeposit: "500",
        utilitiesDeposit: "100",
        otherDeposit: "",
        remark: "",
        taxRate: "",
        taxAmount: "",
        purpose: "Residential",
        furtherTerm: "1",
        rentalFurtherTerm: "Market Rate",
        offerToLeaseType: "RSD - Residential",
        offerToLeaseDocuments: [],
        specialConditions: ""
      };
    },
    submit() {
      if (this.$refs.archiveRentForm.checkValidation()) {
        try {
          this.$refs.taForm.submitAll();

          this.$emit("validated", this.archiveContent);
        } catch (error) {
          //
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
